import React from "react";
import  Seo  from "./components/seo/seo";
import { graphql } from "gatsby";
import "../../styles/app.css";

function Politique({ data }) {
  return (
    <div
      dangerouslySetInnerHTML={{
        __html:
          data.contentfulConfidentialiteDescriptionTextNode.childMarkdownRemark
            .html,
      }}
    />
  );
}

export const query = graphql`
  query MentionsPageQuery {
    contentfulConfidentialiteDescriptionTextNode {
      childMarkdownRemark {
        html
      }
    }
  }
`;

export default Politique;

export const Head = () => <Seo tilte="Politique de Confidentialité"/>;
